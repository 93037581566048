body{
    font-family: "Poppins", sans-serif;

}
.table_head{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 15px;
}
.forfontsmall{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
   
}
.toggle-button {
    padding: 10px 30px;
    background-color: #ddd;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 3s ease;
  }
  
  .toggle-button.on {
    background-color: #4CAF50;
    color: white;
    border-radius: 20px;
  }
  
  .toggle-button.off {
    background-color: #f44336;
    color: white;
    border-radius: 20px;
  }