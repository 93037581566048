body{
    font-family: "Poppins", sans-serif;

}
.table_head{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 15px;
}
.forfontsmall{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
   
}
.formobile_viewonly{
    display: none;
}
@media (max-width: 640px) {
    .forMobile_view{
        display: flex;
        flex-direction: column;
    }
    .tableForMobile{
        display: none;
    }
    .formobile_viewonly{
        display: block;
    }

    /* .mobile-view .grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 0.5rem;
      }
      .mobile-view .grid > div {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
        border-bottom: 1px solid #D3D3D3;
      }
      .mobile-view .grid > div > span {
        font-weight: 500;
      }
      .mobile-view .grid > div > span:last-child {
        color: #00AFE2;
      } */
  }

  .date-input {
    color: #ccc; 
    
  }
  
  .date-input:focus {
    color: #333; /* text color on focus */
  }
  
  .date-input:invalid {
    color: #ccc; /* placeholder color if date is invalid */
  }
  .date-input::placeholder {
    color: #ccc; /* Placeholder text color */
  }
  
  .date-input:focus::placeholder {
    color: transparent; /* Hide placeholder text on focus */
  }

  /* input[type=date] {
    text-align: right;
  } */
  
  input[type="date"]:before {
    color: lightgrey;
    content: unset !important;
    margin-right: unset !important;
  }
  