#search::placeholder {
  text-align: center;
}

.header {
  width: 100%;
  height: inherit;
  display: flex;
}

.header_left {
  width: 70%;
  padding-top: 5px;
  height: 80px;

}

.right {
  width: 30%;
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (max-width: 768px) {

  .dashboardHeading {
    display: none;
    width: 100%;
    height: inherit;
    font-size: 1rem;
  }

  .mobile_view_header_img {
    display: block;
    width: 47px;
    height: 47px;
    background-repeat: no-repeat;
    /* margin-left:; */
    /* margin: auto; */
    background-image: url('../../../public/logo-trace\ 1.png');
  }

  .full_screen {
    display: none;

  }

  .card {
    /* width: 90%;
    padding: 0px !important;
    margin: 0px !important;
    border: 1px solid red; */
  }

  /* .card {
    margin: 0.25rem;
    padding: 0.25rem;
  }

  .card_img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .card_img img {
    height: 1.25rem;
    width: 1.5rem;
  }

  .number {
    font-size: 1.25rem;
  }

  .title {
    font-size: 0.875rem;
  }

  .btn {
    font-size: 0.75rem;
  } */

}

@media screen and (max-width: 1024px) {
  /* .search_icon{
      margin-top:0px;
    } */

}

@media screen and (max-width: 768px) {
  .header {
    width: 80%;
    height: inherit;
    margin-left: 15%;


  }

  .header_left {
    width: 80%;
    height: inherit;
    display: flex;
    justify-content: space-evenly;


  }

  .togglebutton {
    padding-left: 40px;
    padding-top: 5px;

  }

  .mainDiv {
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .admin_name {
    display: none;
  }

  .city_select {
    display: none;
  }

  .search_bar {
    display: none;
  }

  .right {
    width: 20%;

    margin: 0;
    padding: 0;
  }

  .city_name {
    background-color: white;
    color: black;
    width: 100%;
    left: 0;
    text-transform: capitalize;
    border-radius: 5px;
    top: 30px;
    padding-left: 20px;
    color: gray;
  }


}












.button-mob {
  display: inline-block;
  margin: 4px 2px;
  background-color: #fff;
  font-size: 14px;
  padding-left: 32px;
  padding-right: 32px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #023565;
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search-container {
  position: relative;
  display: inline-block;
  margin: 4px 2px;
  height: 50px;
  width: 50px;
  vertical-align: bottom;
}

.mglass {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.searchbutton {
  position: absolute;
  font-size: 22px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.search:focus+.searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search {
  position: absolute;
  left: 20px;
  background-color: white;
  outline: none;
  border: #023565;
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}

.search:focus {
  width: 130px;
  padding: 0 16px 0 0;
}

.expandright {
  left: auto;
  right: 49px;
  background-color: #C60E6B;
  color: #fff;
}

.expandright:focus {
  padding: 0 0 0 16px;
}




/* .searchInput{
    position: absolute;
    top: -10px;
    left: -320px;
} */
/* .mobile_view_header_img{
  display: none;
} */

@media screen and (min-width:600px) and (max-width:1024px) {}