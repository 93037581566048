/* MyCalendar.css */
.highlight-date .rbc-day-bg {
    background-color: transparent;
    /* Ensure background of the cell remains transparent */
}

.highlight-date .rbc-date-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.highlight-date .rbc-date-cell a {
    background-color: #C60E6B;
    color: #fff;
    /* Optional: change text color to contrast with background */
    border-radius: 50%;
    padding: 5px 10px;
    line-height: 1;
    text-align: center;
    display: inline-block;
    min-width: 28px;
    /* Adjust size as needed */
    height: 28px;
    /* Adjust size as needed */
}

/* MyCalendar.css */
.highlight-date .rbc-day-bg {
    background-color: transparent;
    /* Ensure background of the cell remains transparent */
}

.highlight-date .rbc-date-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* MyCalendar.css */
.highlight-date .rbc-button-link {
    background-color: #C60E6B;
    color: #fff;
    /* Optional: change text color to contrast with background */
    border-radius: 50%;
    padding: 5px 10px;
    line-height: 1;
    text-align: center;
    display: inline-block;
    min-width: 28px;
    /* Adjust size as needed */
    height: 28px;
    /* Adjust size as needed */
}


.rbc-background-event {
    background-color: "#C60E6B";
}






/* CalendarToolbar.module.css */

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
}

.button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.label {
    font-size: 1.25rem;
    font-weight: bold;
}