@media screen and (min-width:1024px) {
    
}
.sidebar_img{
    width: 224px;
}
.dashboardHeader{
    width: 288px;
}
nav{
    background-color: #023565;
    color: #FFFFFF;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}
.IconComponent{
    font-size: 21px;
}
.list_element:hover{
    background-color: #C60E6B;
}
.nav{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}



.Pokercardbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .Pokercardbar::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  .Pokercardbar::-webkit-scrollbar-thumb {
   background-image: linear-gradient(#023565,#023565);
  }