.fortablehead{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #313131;
    
}

.tbodyotherstyle{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: #313131;
}