@media screen and (min-width:1441px) and (max-width:2560px) {
    .forbig_screen{
      
    }
    .adjust_button{
        padding-left: 30px;
        padding-right: 30px;
    }
}
  
.table_head{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 15px;
}
.forfontsmall{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
   
}




.Pokercardbar::-webkit-scrollbar {
    width: 6px;
  }
 
  .Pokercardbar::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
 
  .Pokercardbar::-webkit-scrollbar-thumb {
   background-image: linear-gradient(#023565,#023565);
  }