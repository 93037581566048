.table_head{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 17px;
}
.forfontsmall{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
   
}

