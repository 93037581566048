@tailwind base;
@tailwind components;
@tailwind utilities;

/* Pagination container */
.pagination {
  display: flex;
  margin: 10px 12px;
  list-style-type: none;
  padding: 0;
  justify-content: end;
  align-items: end;

}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* Individual page item */
.pagination li {
  margin: 0 5px;
  background-color: rgb(#919EAB);
}

/* Links inside page items */
.pagination a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #007bff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

/* Active page item */
.pagination .active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Disabled page item */
.pagination .disabled a {
  color: #6c757d;
  pointer-events: none;
}

/* Hover and focus states */
.pagination a:hover,
.pagination a:focus {
  background-color: #e9ecef;
  color: #0056b3;
  border-color: #dee2e6;
}


.multiselect-input-container {
  background-color: #f8f9fa;
  border: 1px solid #d1d5db;
  color: #1f2937;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 0.625rem;
  width: 100%;
  overflow-y: scroll;
  height: 160px;
}

.multiselect-options-container {
  max-height: 160px;
  overflow-y: auto;
}



.custom-modal .react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.13);
}

.custom-modal .react-confirm-alert {
  background: rgba(255, 255, 255, 0.144);
}


.searchWrapper {
  max-height: 65px !important;
  max-width: 600px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.search-wrapper {
  min-height: 70px !important;
  max-height: 39px !important;
  padding-left: 8px !important;
}