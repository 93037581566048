/* @media screen and (max-width:382px) {
        .for_mobile_screen{
                overflow: scroll;
        }
} */
.table_head{
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 15px;
    }
    .forfontsmall{
        font-family: "Poppins", sans-serif;
        font-weight: 400; }

        .switch{
                display: flex;
                justify-content:space-between;
                place-content: center;
                width: 80px;
                height: 39px;
                place-items: center;
                position: relative;
        }
        .switch input{
            opacity: 0;
            width: 0;
            height: 0;
        }
        .slider{
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid black;
            border-radius: 20px;
        }
        .slider::before{
            position: absolute;
            content: "";
            width: 26px;
            left: 6px;
            top: 6px;
            height: 26px;
            border-radius: 100%;
            background-color: red;
            transition: 1s;
        }
        input:checked + .slider:before{
            transition: 1s;
            transform: translateX(35px);
            background-color: green;
           
            font-weight: 900;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }