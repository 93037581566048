@media only screen and (max-width: 468px) {
   .buttonDisplay{
    display: flex;
    flex-direction: column;
    text-align: center;
   }
  }

  .tableHead{
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .tbodyfont{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }