.table_head {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 15px;
}
.forfontsmall {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.hover_clss {
  display: none;
  width: 100px;
  height: 100px;
  background-color: black;
}
.hover_show:hover .hover_clss {
  display: block;
}


.Pokercardbar::-webkit-scrollbar {
  width: 6px;
}

.Pokercardbar::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.Pokercardbar::-webkit-scrollbar-thumb {
 background-image: linear-gradient(#023565,#023565);
}