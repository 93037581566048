@media screen and (max-width:425px) {
  .section_button {
    display: flex;
    flex-direction: column;
  }
}

.show {
  display: none;
}

.hover:hover .show {
  cursor: pointer;
  display: block;
}